<script setup>
import { ref, defineEmits, inject } from 'vue';

const emit = defineEmits(['save']);

const dialogRef = inject('dialogRef');

const fechar = (sucesso = false) => {
    dialogRef?.value.close(sucesso);
};

const loading = ref(false);
const salvar = async () => {
    loading.value = true;
    const promise = new Promise((resolve) => {
        emit('save', resolve);
    });
    const resolvePai = await promise;
    if (resolvePai) {
        resolvePai();
        fechar(true);
    }
    loading.value = false;
};

const ciente = ref(false);
</script>

<template>
    <div class="flex flex-column gap-3">
        <p>
            Você é responsável pelas informações preenchidas no campo de descrição das atividades do cargo. Certifique-se de que estejam corretas e em
            conformidade com o CBO.
        </p>

        <div class="flex align-items-center gap-2">
            <Checkbox id="ciente" v-model="ciente" binary />
            <label for="ciente">Ciente da responsabilidade</label>
        </div>

        <span class="flex justify-content-end gap-3">
            <Button outlined label="Voltar" @click="fechar(false)" />
            <Button :disabled="!ciente" label="Salvar" :loading="loading" @click="salvar" />
        </span>
    </div>
</template>
